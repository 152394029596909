<div class="horizontal-cell-divider">
  <div class="segment" />
  <div class="segment" />
  <div class="segment" />
</div>

<style>
  .horizontal-cell-divider {
    width: 100%;

    display: flex;
    justify-content: space-around;
  }

  .segment {
    background-color: var(--foreground);
    height: 2px;

    width: 25%;

    border-radius: 50px;
  }
</style>
